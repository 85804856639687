.userBox{
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 10px 40px;
}
.col_flex{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
}