.boxFlex{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.balanceBox{
    padding: 20px;
    box-sizing: border-box;
}
.top_search{
    width: 100%;
    min-height: 50px;
    background-color: #fff;
    padding: 20px 20px 5px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.ant-form-item{
    margin-bottom: 15px !important;
}
.Statistics{
    width: 100%;
    height: auto;
    background-color: #fff;
    margin-top: 10px;
    display: flex;
}
.stat_item{
    width: 20%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.stat_item::after{
    content: '';
    width: 1px;
    height: 60px;
    background-color: #e9e9e9;
    position: absolute;
    right: 0;
    top: 20px;
}
.stat_item:last-child::after{
    width: 0;
}
.stat_item_box{
    display: flex;
    flex-direction: column;
}
.stat_item_box span{
    line-height: 2;
    font-size: 16px;
}
.box_span{
    color:#999;
    font-size: 14px !important;
}
.tableList{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    flex:1;
    background-color: #fff;
}
.ant-menu-item-selected{
    background-color: #fff !important;
}
.tableList1{
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    flex:1;
    background-color: #fff;
}